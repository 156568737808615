<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
const moment = require('moment');
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import StatDashboardTicket from "@/components/widgets/stat-dashboard-ticket";
import Loading from 'vue-loading-overlay';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


/**
 * Elements component
 */
export default {
  page: {
    title: "Form Ticket Gangguan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, StatDashboardTicket, Loading ,DatePicker},
  data() {
    return {
      title: "Beranda Ticket",
      //param create ticket
      userId: null,
      submittedForm: false,
      userName: null,
      testLoading: true,
      hasData: false,
      isLoading: false,
      runTroubleShoot: false,
      troubleShoot: false,
      settingId: '',
      openTime:false,
      data: {
        category: '',
        problems: '',

        stage: {
          code: 'ticketSubmitted',
          createdAt: moment().format(),
          createdBy: this.$store.state.authfack.user.username
        },
        status: 'open',
        priority: '',
        customerId: '',
        customerProduct: '',
        deadlineInstallation:'',
        isCustomer: false
      },
      choice: {
        customerProduct: {
          code: '',
          label: 'Pilih'
        },
        category: {
          code: '',
          label: 'Pilih'
        },
        priority: {
          code: '',
          label: 'Pilih'
        }
      },
      testVariant: "primary",
      troubleShootLoading: false,
      cardTroubleShooting: false,
      categoryList: [
        {
          label: 'Internet',
          code: 'internet'
        },
        {
          label: 'Administration',
          code: 'admin'
        },
        {
          label: 'Installation',
          code: 'installation'
        }
      ],
      priorityList: [
        {
          label: 'Low',
          code: 'low'
        },
        {
          label: 'High',
          code: 'high'
        }
      ],
      listCustomer: [],
      listProduct: [],
      //apex chart left
      series: [{
        name: "Jumlah Ticket",
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Petugas 1 '],
        }
      },

      seriesKategori: [{
        name: "Jumlah",
        data: [400, 300, 450]
      },
      ],
      seriesKategoriStacked: [{
        name: 'High',
        data: [44, 55, 41]
      }, {
        name: 'Low',
        data: [53, 32, 33]
      }],

      chartOptionsKategoriStacked: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
        },

        colors: ['#ed1818', '#f5c311'],
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },

        title: {
          text: 'Ticket Per Kategori'
        },
        xaxis: {
          type: 'category',
          categories: ['Admin', 'Instalation', 'lnternet'],
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },

      chartOptionsKategori: {
        chart: {
          type: 'bar',
          height: 250
        },
        colors: ['#33f702'],
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            console.log(config.dataPointIndex)
          }
        },
        xaxis: {
          type: 'category',
          categories: ['Admin  ', 'Instalation ', 'lnternet'],
        },

      },

      seriesMonthFirst: [{
      }],
      chartOptionsMonthFirst: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Waktu Penangganan Awal',
          align: 'left'
        },
        subtitle: {
          text: '30 Hari Terakhir',
          align: 'left'
        },


        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          type: 'datetime',

        },

      },

      seriesMonthFullResolutiuon: [{
      }],
      chartOptionsMonthFullResolution: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: 'smooth'
        },
        colors: ['#fcc11c'],
        title: {
          text: 'Waktu Penyelesaian Ticket',
          align: 'left'
        },
        subtitle: {
          text: '30 Hari Terakhir',
          align: 'left'
        },

        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          type: 'datetime',

        },

      },

      seriesMonthRekap: [],
      chartOptionsMonthRekap: {
        chart: {
          height: 450,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false
          },
        },
        colors: ['#008FFB', '#00E396'],
        stroke: {
          curve: 'smooth',

        },
        fill: {

          type: 'solid',
          colors: ['transparent']
        },
        title: {
          text: 'Ticket Dibuat dan Terselesaikan ',
          align: 'left'
        },
        subtitle: {
          text: '30 Hari Terakhir',
          align: 'left'
        },

        labels: ['Dec 01', 'Dec 02', 'Dec 03', 'Dec 04', 'Dec 05', 'Dec 06', 'Dec 07', 'Dec 08', 'Dec 09 ', 'Dec 10', 'Dec 11', 'Dec 12', 'Dec 13', 'Dec 14', 'Dec 15', 'Dec 16', 'Dec 17', 'Dec 18', 'Dec 19 ', 'Dec 20', 'Dec 21', 'Dec 22', 'Dec 23', 'Dec 24', 'Dec 25', 'Dec 26', 'Dec 27', 'Dec 28', 'Dec 29 ', 'Dec 30', 'Dec 31'],
        dataLabels: {
          enabled: false
        },
        xaxis: {

          type: 'datetime',
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " total";
              }
              return y;
            }
          }
        }


      },


      seriesBarDaily: [{
        name: 'Tersedia',
        data: [44, 55, 41, 67, 22, 43, 23]
      },

      {
        name: 'Selesai',
        data: [13, 23, 20, 8, 13, 27, 11]
      }, {
        name: 'Dalam Pengerjaan',
        data: [11, 17, 15, 15, 21, 14, 20]
      }, {
        name: 'Pengerjaan Selesai',
        data: [11, 17, 15, 15, 21, 14, 12]
      }, {
        name: 'Di Hapus',
        data: [21, 7, 25, 13, 22, 8, 14]
      }],
      chartOptionsBarDaily: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {

          categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
            '01/05/2011 GMT', '01/06/2011 GMT', '01/07/2011 GMT'],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        },
        title: {
          text: 'Aktifitas Penangganan Ticket',
          align: 'left'
        },
        subtitle: {
          text: '7 Hari Terakhir',
          align: 'left'
        },
      },


      items: [
        { Ticket: 'T-242145874700', OrderId: '116010843' },
        { Ticket: 'T-242145874700', OrderId: '116010843' },
        { Ticket: 'T-242145874700', OrderId: '116010843' },
        { Ticket: 'T-242145874700', OrderId: '116010843' }
      ],

      fieldsDatakategori: [

        {
          label: "Nomor Ticket",
          key: "noTicket",
          sortable: true,
          thStyle: { width: "13%" },
        },
        {
          label: "Nama Pelanggan",
          key: "customerName",
          sortable: true,
        },
        {
          label: "Order Id",
          key: "orderId",
          sortable: true,
        },
        {
          label: "Status Ticket",
          key: "status",
          sortable: true,
          thStyle: { width: "10%" },
        },

        {
          label: "Prioritas Ticket",
          key: "priority",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          label: "Stage Ticket",
          key: "stage",
          sortable: true,
          formatter: (value) => {

            if (value.length > 1) {
              return value[value.length - 1].code
            }
            else {
              return value[0].code
            }
          },
          thStyle: { width: "10%" },
        },
        {
          label: "Kategori",
          key: "category",
          sortable: true,
        },
        {
          label: "Assignment",
          key: "assignmentGroup",
          sortable: true,
          thStyle: { width: "13%" },
           
        },
        {
          label: "Dibuat Pada",
          key: "createdAt",
          sortable: true,
        },
      ],



      dataLoaded: false,

      apiResponse: null,
      dataToSend: null,


      kategoriData: {
        kategoriAdminData: null,
        kategoriAdminDataHigh: null,
        kategoriAdminDataLow: null,
        kategoriInstalationData: null,
        kategoriInstalationDataHigh: null,
        kategoriInstalationDataLow: null,
        kategoriInternetData: null,
        kategoriInternetDataHigh: null,
        kategoriInternetDataLow: null
      },

      modalTitle: 'Pemberitahuan Tiket <i class="bx bxs-bell-ring "></i>',
      modalNotifCount: null,

      //componen tab alert
      highTab: null,
      lowTab: null,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      maxTime: 10,
      currentTime: 10,
      ticketHighModalCount: 2,
      ticketLowModalCount: 3,

      ticketHighModalCountHtml: null,
      ticketLowModalCountHtml: null,


      fieldsModalWarning: [

        {
          label: "Nomor Ticket",
          key: "noTicket",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          label: "Nama Pelanggan",
          key: "customerName",
          sortable: true,
        },
        {
          label: "Order Id",
          key: "orderId",
          sortable: true,
        },
        {
          label: "Status Ticket",
          key: "status",
          sortable: true,
        },

        {
          label: "Prioritas Ticket",
          key: "priority",
          sortable: true,
        },
        {
          label: "Stage Ticket",
          key: "stage",
          sortable: true,
          formatter: (value) => {

            if (value.length > 1) {
              return value[value.length - 1].code
            }
            else {
              return value[0].code
            }
          },
        },
        {
          label: "Kategori",
          key: "category",
          sortable: true,
        },
        {
          label: "Assignment",
          key: "assignment",
          sortable: true,
        },
        {
          label: "Dibuat Pada",
          key: "createdAt",
          sortable: true,
        },
        { key: 'actions', label: '' }
      ],

      warningData: {
        ticketHighPriority: null,
        ticketLowPriority: null,
      },
      updateWarningSelected: null,
      optionsPetugas: null,
      selectedPetugas: [],
      selectedPetugasCount: 1,
      selectedDateTime: {
        date: null, 
        time: null, // Untuk menyimpan waktu yang dipilih
      },
      optionsStatus: ['Open', 'Close'],
      selectedStatus: null,

      optionsStage: ['Dalam Pengerjaan', 'Pengerjaan Selesai', 'Ticket Selesai'],
      selectedStage: null,
      selectedStageDetail: null,
      cardOptionResult: {
        oltRx: null,
        onuRx: null,
        onuSignal: null,
        onuIP: null,
        onuUptime: null,
        onuState: null,
        result: null,
        code: null,
        checkingPayment: null,
        ticketId: null,
        stage_id: null
      },

      inputValue: '',
      loading: true,
      stateTroubleShoot: {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

      loadingStateTroubleShoot: {
        oltRx: true,
        onuRx: true,
        signal: true,
        onuIP: true,
        upTime: true,
        status: true,
        result: true
      },

      StateTroubleShootValue: {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null,
        code: null,
        checkingPayment: null,
        ticketId: null,
        stage_id: null
      },

      holderUpdateAssigment: null,

      holderUpdateStageProses: null,
      holderIdPetugas: null,
      holderSelectedIdPetugas: [],
      holderRowData: null,
      kategoriAltInstal:false




    };
  },
  validations: {
    data: {
      customerProduct: {
        required
      },
      priority: {
        required
      },
      category: {
        required
      },
      problems: {
        required
      },
    }
  },
  async created() {
    let loader = this.$loading.show({
      loader: 'spinner',
      color: '#ff5024'
    });
    await this.getDashboardData()
    this.dataLoaded = true
    this.$refs.chartOptionsMonthFirst.refresh()
    this.$refs.chartOptionsMonthFullResolution.refresh()
    this.$refs.chartOptionsBarDaily.refresh()
    this.$refs.seriesKategoriStacked.refresh()
    setTimeout(() => {
    }, 2000);

    loader.hide()
  },

  async mounted() {

  },
  sockets: {
    connect: function () {
      console.log('socket connected ticket')
    },
    updateTicket: async function () {


      await this.getDashboardData()
      console.log("socket ini socket ticket");
    },

  },
  watch: {
    'choice.customerProduct': {
      immediate: true,
      handler(newValue) {
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.customerProduct = newValue.code
        }
      }
    },
    'choice.category': {
      //immediate: true,
      handler(newValue) {
        console.log("halo",newValue);
        if (newValue.code == "installation"){
            this.kategoriAltInstal = true
           
          }
          else {
            this.kategoriAltInstal = false
          }
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.category = newValue.code
          
        }
        this.choice.priority = null
        this.runTroubleShoot = false
      }
    },
    'choice.priority': {
    //  immediate: true,
   
      handler(newValue) {
        console.log("nwew",newValue);
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.priority = newValue.code
          if(this.data.category != "installation"){
            this.troubleShoot = true
            this.runTroubleShoot = true
          }
          else {
            this.troubleShoot = false
            this.runTroubleShoot = true
          }

         
        }
        console.log("noiseeee",this.data);
      }
    },
    'selectedDateTime':{
      deep:true,
      handler(value){
        if(value.time != null && value.date != null){
          
          let dateString =  `${value.date} ${value.time}`;
          const datetime  = moment(dateString).format('YYYY-MM-DD HH:mm:ss')
          this.data.deadlineInstallation= datetime
        }
        
      }
    },
   

    'selectedStageDetail': {

      handler(newValue) {
        this.holderUpdateStageProses = null
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          console.log(newValue);
          this.cardOptionResult.code = null
          this.cardOptionResult.code = newValue
          console.log(this.StateTroubleShootValue);
          if (newValue == "Dalam Pengerjaan") {
            this.holderUpdateStageProses = "ticketOnProgress"
          }
          else if (newValue == "Pengerjaan Selesai") {
            this.holderUpdateStageProses = "ticketProgressDone"
          }
          else if (newValue == "Ticket Selesai") {
            this.holderUpdateStageProses = "ticketDone"
          }


        }



      }
    },
    'selectedPetugas': {
      deep: true,
      handler(value) {
       
        for (let index = 0; index < value.length; index++) {
          for (let index2 = 0; index2 < this.optionsPetugas.length; index2++) {
            if (this.optionsPetugas[index2] == value[index]) {
              this.holderSelectedIdPetugas[index] = this.holderIdPetugas[index2]
            }
          }
          
        }

        console.log("sini bang ",   this.holderSelectedIdPetugas);

       
      }
     
    },


  },
  methods: {
    async kategoriBar(event, chartContext, config) {

      const seriesIndex = config.seriesIndex;
      const dataPointIndex = config.dataPointIndex;
      const category = config.globals.seriesNames[seriesIndex];
      const seriesName = config.globals.labels[dataPointIndex];

      if (seriesName == "Admin") {
        if (category == "High") {
          this.showModalAdminHigh()
        }
        else if (category == "Low") {
          this.showModalAdminLow()
        }
      }
      else if (seriesName == "lnternet") {
        if (category == "High") {
          console.log("high lnternet");
          this.showModallnternetHigh()
        }
        else if (category == "Low") {
          console.log("low lnternet");
          this.showModallnternetLow()
        }
      }
      else if (seriesName == "Instalation") {
        if (category == "High") {
          console.log("high Instalation");
          this.showModalInstalationHigh()

        }
        else if (category == "Low") {
          console.log("low Instalation");
          this.showModalInstalationLow()
        }
      }


      if (config.dataPointIndex == 0)
        console.log("test sini");
    },

    rowClass(item, type) {

      if (type === 'row') {
        return item.status === "open" && item.isRead === "false" ? 'table-danger' : ''
        //    item.priority === 'high' ? 'priority-high' : item.priority === 'low' ? 'priority-low' : ''

      }
      return '';
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    async getDashboardData() {

      let date= new Date()
      console.log("dateee",date);
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket').then(response => {
        this.apiResponse = response.data
        console.log("ini aja bang", response.data);
        this.chartOptions = {
          xaxis: {
            categories: response.data.namaPetugas,

          }
        }
        // this.chartOptionsMonthFirst= {
        // xaxis: {
        //     categories: response.data.monthDayLabel,
        //     type: 'datetime',
        //   }
        // }

        this.chartOptionsMonthFirst.xaxis.categories = response.data.monthDayLabel

        this.chartOptionsMonthFullResolution.xaxis.categories = response.data.monthDayLabel

        this.chartOptionsBarDaily.xaxis.categories = response.data.seriesWeeklyActvity.weeklyDayLabel


        console.log("labels after", this.chartOptionsMonthFullResolution.xaxis.categories);
        this.series = []
        this.series = [{ data: response.data.countAssigmentEachPetugas }]

        this.seriesMonthFirst = [{
          name: 'Menit Penanganan Awal',
          type: 'area',
          data: response.data.seriesPenanggananAwal

        }]
        console.log("kategori", response.data.kategoriData.kategoriInternetData.length);
        this.seriesMonthFullResolutiuon = [{
          name: 'Menit Waktu Penyelesaian Ticket',
          type: 'area',
          data: response.data.seriesTicketTimeFinish

        }]
        this.seriesMonthRekap = [{
          name: 'Dibuat',
          data: response.data.seriesCreatedAndClosed.seriesTicketCreated
        }, {
          name: 'Selesai',
          data: response.data.seriesCreatedAndClosed.seriesTicketClose
        }]

        this.seriesBarDaily = [
          {
            name: 'Dibuat',
            data: response.data.seriesWeeklyActvity.seriesTicketCreatedWeekly
          }, {
            name: 'Tersedia',
            data: response.data.seriesWeeklyActvity.seriesTicketOnProgress
          }, {
            name: 'Selesai',
            data: response.data.seriesWeeklyActvity.seriesTicketCloseWeekly
          }, {
            name: 'Dalam Pengerjaan',
            data: response.data.seriesWeeklyActvity.seriesTicketProgressDone
          }, {
            name: 'Pengerjaan Selesai',
            data: response.data.seriesWeeklyActvity.seriesTicketDone
          }
        ]


        this.seriesKategoriStacked = [{
          name: 'High',
          data: [response.data.kategoriData.kategoriAdminDataHigh.length, response.data.kategoriData.kategoriInstalationDataHigh.length, response.data.kategoriData.kategoriInternetDataHigh.length]
        }, {
          name: 'Low',
          data: [response.data.kategoriData.kategoriAdminDataLow.length, response.data.kategoriData.kategoriInstalationDataLow.length, response.data.kategoriData.kategoriInternetDataLow.length]
        }
        ]

        // this.kategoriData = response.data.kategoriData
        //ubah kondisi if menjadi !=0 , dan if di dalamnya
        if (response.data.dataTicketOpen.dataOpenTicketHighWarning.data.length == 0 || response.data.dataTicketOpen.dataOpenTicketLowWarning.data.length != 0) {
          if (response.data.dataTicketOpen.dataOpenTicketHighWarning.data.length == 0) {
            let dataNotif = "sini"
            this.modalNotifCount = 2
            //ganti datanya nanti
            this.warningData.ticketHighPriority = response.data.dataTicketClose.dataCloseTicket
            this.warningData.ticketLowPriority = response.data.dataTicketOpen.dataOpenTicketLowWarning.data
            this.checkNotif(dataNotif)
            console.log(" this.warningData", this.warningData);
          }
          else {
            return;
          }
        }

      })

      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?stageDetailMountly=true').then(response => {
        console.log("new responseeee", response.data);

        this.kategoriData = response.data.ticketDataPlusDetailKategorimountly
        console.log("kategori", this.kategoriData);
      })
    },
    async showLoadingOverlay() {
      this.runTroubleShoot = false

      await setTimeout(() => {

      }, 0);
      // Simulate some asynchronous operation, e.g., fetching data
      setTimeout(() => {
        this.loadingStateTroubleShoot.signal = false

        this.StateTroubleShootValue.onuSignal = this.cardOptionResult.onuSignal
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.signal = true
        }
        else {
          this.stateTroubleShoot.signal = false
        }
      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {

        this.loadingStateTroubleShoot.oltRx = false;

        this.StateTroubleShootValue.oltRx = this.cardOptionResult.oltRx
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.oltRx = true
        }
        else {
          this.stateTroubleShoot.oltRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuRx = false;
        this.StateTroubleShootValue.onuRx = this.cardOptionResult.onuRx

        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.onuRx = true
        }
        else {
          this.stateTroubleShoot.onuRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {


        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.onuState = this.cardOptionResult.onuState

        if (this.StateTroubleShootValue.onuState == "working") {
          this.stateTroubleShoot.status = true
        }
        else {
          this.stateTroubleShoot.status = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.upTime = false;
        this.StateTroubleShootValue.onuUptime = this.cardOptionResult.onuUptime
        if (this.StateTroubleShootValue.onuUptime != null) {
          this.stateTroubleShoot.upTime = true
        }
        else {
          this.stateTroubleShoot.upTime = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuIP = false;
        this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
        if (this.StateTroubleShootValue.onuIP != null) {
          this.stateTroubleShoot.onuIP = true
        }
        else {
          this.stateTroubleShoot.onuIP = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.result = this.cardOptionResult.result
        this.runTroubleShoot = true
      }, 0); // Simulating a 2-second loading delay

    },




    resetLoadingState() {
      this.stateTroubleShoot = {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

        this.loadingStateTroubleShoot = {
          oltRx: true,
          onuRx: true,
          signal: true,
          onuIP: true,
          upTime: true,
          status: true,
          result: true
        },

        this.StateTroubleShootValue = {
          oltRx: null,
          onuRx: null,
          onuSignal: null,
          onuIP: null,
          onuUptime: null,
          onuState: null,
          result: null,
          code: null,
          checkingPayment: null,
          ticketId: null,
          stage_id: null
        }

    },

    handleChange(value, type){
      console.log("type",type);
      if (type === 'second') {
        this.openTime = false;
      }
    },




    checkNotif(dataNotif) {
      console.log(dataNotif);
      this.showAlert()

      this.$refs['modal-notif'].show()
      let countdown = setInterval(() => {
        if (this.currentTime > 0) {
          this.currentTime--;
        } else {
          clearInterval(countdown);

          this.ticketHighModalCountHtml = "Ticket High Priority " + "( " + this.ticketHighModalCount + " )"
          this.ticketLowModalCountHtml = "Ticket Low Priority " + "( " + this.ticketLowModalCount + " )"
          this.hideModalNotif()
          this.$refs['modal-alret-Data'].show()

        }
      }, 1000);
    },
    showModalAdminHigh() {
      this.$refs['modal-Admin-high'].show()
    },
    showModalAdminLow() {
      this.$refs['modal-Admin-low'].show()
    },

    showModalInstalationHigh() {
      this.$refs['modal-Instalation-high'].show()
    },
    showModalInstalationLow() {
      this.$refs['modal-Instalation-low'].show()
    },

    showModallnternetHigh() {
      this.$refs['modal-lnternet-high'].show()
    },
    showModallnternetLow() {
      this.$refs['modal-lnternet-low'].show()
    },
    testNewModal() {
      this.$refs['modal-test-card'].show()
    },
    showModalTroubleShootOnStage(row, item) {



      console.log("rwoww", row, item);
      this.runTroubleShootMethod(row, item)
      this.$refs['modal-onStage-troubleShoot'].show()
      this.showLoadingOverlay()

    },


    showModalAlertData() {
      this.currentTime = 0
      console.log("closed blank view");
    },

    testNewModalClose() {
      this.$refs['modal-test-card'].hide()
    },

    hideModalAdminHigh() {
      this.$refs['modal-Admin-high'].hide()
    },
    hideModalAdminLow() {
      this.$refs['modal-Admin-low'].hide()
    },

    hideModalInstalationHigh() {
      this.$refs['modal-Instalation-high'].hide()
    },
    hideModalInstalationLow() {
      this.$refs['modal-Instalation-low'].hide()
    },

    hideModallnternetHigh() {
      this.$refs['modal-lnternet-high'].hide()
    },
    hideModallnternetLow() {
      this.$refs['modal-lnternet-low'].hide()
    },
    hideModalNotif() {
      this.$refs['modal-notif'].hide()
    },


    async updatePatchAssigemnt() {

      let data = {
        petugas: this.holderSelectedIdPetugas
      }
      await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.holderUpdateAssigment.id, data).then(async response => {
        await this.getDashboardData()

        this.$refs['modal-update-petugas'].hide()
        return response
      })
    },

    async notificationChecker(row) {
      console.log(
        "asidahjsidj", row.item
      );

      let holderRow = row.item
      let holderStageTIcketProgressDone = false
      let holderPatch = {
        id: null,
        ticketNo: null,
        stageNo: [],
        isRead: null,
        isAllStageRead: null,
      }
      let notificationUpdateCheck = {
        text: holderRow.noTicket,
        stageIndex: null
      }

      if (row.item.isRead == "false") {
        //ganti if length > 1 nanti
        if (row.item.isRead == "false" && row.item.stage.length >= 1) {

          for (let index = 0; index < row.item.stage.length; index++) {
            if (row.item.stage[index].stageData.code !== "ticketProgressDone" && holderRow.stage[index].stageData.isReadStage == 0) {
              if (row.item.stage[index].stageData.code === "ticketSubmitted" && row.item.stage[index].stageDetail !== null) {
                console.log("centos");
              }
              else {
                console.log("kata");
              }
              holderRow.stage[index].stageData.isReadStage = 1
              notificationUpdateCheck.stageIndex = index
              holderStageTIcketProgressDone = false

            }
            else {
              console.log("sini");
              holderRow.stage[index].stageData.isReadStage = 1
              holderStageTIcketProgressDone = true
            }
          }
        }
        else {
          holderRow.stage[0].stageData.isReadStage = 1
          holderStageTIcketProgressDone = false

        }


        if (holderStageTIcketProgressDone == true) {
          holderRow.isRead = false
          holderRow.isAllStageRead = false
        }
        else {
          holderRow.isRead = true
          holderRow.isAllStageRead = true
        }

        console.log("holderRow", holderRow);
        holderPatch.ticketNo = holderRow.noTicket

        let holderStageChecker = false
        for (let index = 0; index < holderRow.stage.length; index++) {
          holderPatch.stageNo.push(holderRow.stage[index].stageData.isReadStage)
          if (holderRow.stage[index].isReadStage == 0) {
            holderStageChecker = false
          }
          else {
            holderStageChecker = true
          }
        }

        let testString = "ticketNo=" + notificationUpdateCheck.text + "&stageIndex=" + notificationUpdateCheck.stageIndex
        holderPatch.isRead = holderRow.isRead
        holderPatch.isAllStageRead = holderStageChecker
        holderPatch.id = holderRow.id
        console.log("holder", holderPatch);
        console.log("row data", row);
        console.log("last dance", notificationUpdateCheck);
        console.log("logfjfj", testString);

        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-notif-dashboard?' + testString).then(responseUpdate => {
          console.log("update", responseUpdate)
          row.item.isAllStageRead = true
          row.item.isRead = true
          return responseUpdate
        })
      }
    },

    async runTroubleShootMethod(row, item) {

      this.resetLoadingState()
      console.log("ini siapa yaaa", row);
      console.log("this data", item);

      if (row != null) {

        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot?troubleshot=' + row.orderId).then(async response => {
          this.resetLoadingState()

          this.cardOptionResult = response.data
          this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
          this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
          this.StateTroubleShootValue.ticketId = row.noTicket
          this.StateTroubleShootValue.code = item.stageData.code
          this.cardOptionResult.ticketId = row.noTicket
          this.cardOptionResult.code = item.stageData.code
          this.cardOptionResult.stage_id = item.stageData._id
          if (row.stage.length >= 1) {
            this.StateTroubleShootValue.stage_id = item.stageData._id

          }
          else {
            this.StateTroubleShootValue.stage_id = item.stageData._id
            console.log("false");
          }

          await this.showLoadingOverlay()



          if (item.stageDetail == null) {
            await axios.post(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot', this.cardOptionResult)

          }




          this.holderRowData = row
          console.log("StateTroubleShootValue", this.StateTroubleShootValue);
          console.log("item data", item);
          console.log("card option", this.cardOptionResult);
          // await  axios.get(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot?troubleshot=' +row.orderId)
          //  this.updateStageOnDetail(row)




          return response
        })
      }
      //this.runTroubleShoot = false
      else {
        this.troubleShootLoading = true
        this.cardTroubleShooting = true
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-analytic?troubleshot=' + this.data.customerProduct).then(response => {
          this.cardOptionResult = response.data
          console.log("result trouble shoot ", this.cardOptionResult);
          this.showLoadingOverlay()
          return response
        })
      }
      //

    },



    //method for create ticket
    async getCustomer(loading, search, vm) {
      vm.listCustomer = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?searchCustomer=' + search)
        .then(response => {
          loading(false);
          return response.data
        })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.getCustomer(loading, search, this);
      }
    },
    async setSelectedCustomer(value) {
      this.data.customerId = value.id
      this.listProduct = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?customerIdTicket=' + value.id)
        .then(response => {
          return response.data.map(function (val) {
            return {
              label: val.orderId,
              code: val.id
            }
          })
        })
    },
    async setSelectedProduct(value) {
      // console.log('prodyc', value)
      this.data.customerProduct = value.code
    },
    async submit() {
      console.log("datanyaaa", this.data)
      console.log("datanya stage ", this.StateTroubleShootValue)

      this.submittedForm = true;
      this.$v.data.$touch();
      if (this.$v.data.$invalid) {
        console.log('gagal', this.$v)

        // console.log('gagal', this.data)
        return;
      } else {

        let loader = this.$loading.show({
          // Optional parameters
          container: null,
          canCancel: false,
          color: '#1976D2'
        });
        loader.hide()
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'ticket', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(async response => {
            this.StateTroubleShootValue.ticketId = response.data.ticketNo
            this.StateTroubleShootValue.code = response.data.stage.code
            this.StateTroubleShootValue.stage_id = response.data.stage[0]._id
            this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
            await axios.post(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot', this.StateTroubleShootValue).then(res2 => {
              return res2;
            })

            loader.hide()
            Swal.fire("Sukses!", "Data berhasil disimpan", "success");
            this.$router.push({ path: '/' })
            console.log("datanya stage final", this.StateTroubleShootValue)
          }).catch(error => {
            loader.hide()
            Swal.fire("Gagal!", "Data gagal disimpan, " + error.message, "error");
          })
      }
    },


    async updateWarningSetting(row) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?petugas=true').then(response => {
        console.log("array petugas", response);
        this.holderIdPetugas = response.data.idPetugas
        return this.optionsPetugas = response.data.dataPetugas
      })
      this.$refs['modal-update-petugas'].show()
      console.log("row setting", row);
      this.holderUpdateAssigment = row.item
    },

    updateWarningStatus(row) {
      console.log("status Seleceted", this.optionsStatus);
      this.$refs['modal-update-status'].show()
      console.log("row status", row);
    },

    updateWarningStage(row) {
      console.log("status Seleceted", this.optionsStage);
      this.$refs['modal-update-stage'].show()
      console.log("row Stage", row);
    },

    updateWarningDelete(row) {
      Swal.fire({
        title: "Apakah Anda Ingin Menhapus Ticket?",
        text: "Ticket Yang Terhapus Tidak Bisa Di Kembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batalkan",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Terhapus!",
            text: "Ticket Berhasil Dihapus",
            icon: "success"
          });
        }
      });
      console.log("row Delete", row);
    },

    async updateStageOnDetail(row) {
      console.log("update stage", this.StateTroubleShootValue);
      console.log("holder stage", this.holderUpdateStageProses);
      console.log("row update", row);
      console.log("auth", this.$store.state.authfack.user)

      Swal.fire({
        title: "Apakah Anda Ingin Meng Update Ticket?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Update",
        cancelButtonText: "Batalkan",
      }).then(async (result) => {
        if (result.isConfirmed) {

          let patchStageDate = {
            code: this.holderUpdateStageProses,
            createdAt: moment(),
            createdBy: this.$store.state.authfack.user.username
          }

          await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.holderRowData.id, patchStageDate).then(responseUpdate => {
            console.log("update", responseUpdate.data.data.response.stage[responseUpdate.data.data.response.stage.length - 1]._id);

            this.StateTroubleShootValue.stage_id = responseUpdate.data.data.response.stage[responseUpdate.data.data.response.stage.length - 1]._id
            this.StateTroubleShootValue.code = responseUpdate.data.data.response.stage[responseUpdate.data.data.response.stage.length - 1].code

          }).then(async responseDetail => {
            console.log(responseDetail);

            return await axios.post(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot', this.StateTroubleShootValue).then(responsePost => {
              console.log(responsePost);
              return responsePost
            })

          })

          Swal.fire({
            title: "Update",
            text: "Ticket Berhasil Di Update",
            icon: "success"
          });
          await this.getDashboardData()
          this.$refs['modal-onStage-troubleShoot'].hide()
        }
        else {
          this.$refs['modal-onStage-troubleShoot'].hide()
        }
      });





    },
    updateAssigementSeleceted (index){
      console.log("index nya bang",index);
      this.holderSelectedIdPetugas.splice(index,1)
        this.selectedPetugas.splice(index,1)
      this.selectedPetugasCount--
    }
  


  },


  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loading v-model="isLoading" :active="isLoading"></loading>

    <StatDashboardTicket ref="statDashboard" :dataFromParent="dataToSend" v-if="dataLoaded"></StatDashboardTicket>
    <div class="row">
      <div class="col-3">
        <div class="custom-accordion">
          <div class="card mt-0 mb-0 pt-0">
            <a href="javascript: void(0);" class="text-dark" data-toggle="collapse"
              v-b-toggle.checkout-billinginfo-collapse>
              <h2 class="card-title"> </h2>
              <div class="p-3">
                <div class="media align-items-center">

                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-14 mb-1">Tambahkan Ticket </h5>
                    <i class="text-muted text-truncate mb-0 mt-0 pb-0 font-size-10">

                    </i>
                  </div>
                  <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-billinginfo-collapse" visible>
              <div class="p-2 pt-0 border-top">
                <div class="card">
                  <div class="card-body">
                    <!-- <h4 class="card-title">Textual inputs</h4>
            <p class="card-title-desc">
              Here are examples of
              <code>.form-control</code> applied to each textual HTML5
              <code>&lt;input&gt;</code>
              <code>type</code>.
            </p> -->

                    <div class="row">
                      <div class="col-12">
                        <b-alert show v-if="$v.data.$invalid && submittedForm" variant="danger">Mohon isi data dengan
                          benar!</b-alert>
                        <form @submit.prevent="submit" class="form-horizontal" role="form">
                          <div class="form-group row mb-4">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Nama </label>
                            <div class="col-sm-9">
                              <v-select label="customerName" :filterable="false" :options="listCustomer"
                                @search="onSearch" @input="setSelectedCustomer">
                                <template slot="no-options">
                                  Ketik nama pelanggan..
                                </template>
                                <template slot="option" slot-scope="option">
                                  <div class="d-center">
                                    {{ option.customerName }}
                                  </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                  <div class="selected d-center">
                                    {{ option.customerName }}
                                  </div>
                                </template>
                              </v-select>
                            </div>
                          </div>
                          <div class="form-group row mb-4" v-if="data.customerId != ''">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Order ID</label>
                            <div class="col-sm-9">
                              <v-select label="label" v-model="choice.customerProduct" :options="listProduct">
                              </v-select>
                            </div>
                          </div>
                          <div class="form-group row mb-4" v-if="listProduct.length > 0">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Kategori</label>
                            <div class="col-sm-9">
                              <v-select label="label" v-model="choice.category" :options="categoryList">
                              </v-select>
                            </div>
                          </div>

                          <div class="form-group row mb-2" v-if="kategoriAltInstal">
                            <!-- <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">preketek</label> -->
                            <!-- <div class="col-sm-9"> -->
                              <div class="pl-0">
                                <b-form-group  label="Deadline Pemasangan Baru">
              
                                  <div class="form-group row mb-4" v-if="listProduct.length > 0">
                                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label" style="margin-left: ‒20px;">Tanggal</label>
                                  <div class="col-sm-9">
                                    <date-picker
                                  v-model="selectedDateTime.date"
                                  value-type="format"
                                  format="YYYY-MM-DD"
                                  type="date"
                                  placeholder="Select date"
                                  class="mb-1"
                                ></date-picker>
                            </div>
                          </div>

                          <div class="form-group row mb-2" v-if="listProduct.length > 0">
                                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label" style="margin-left: ‒20px;">Waktu</label>
                                  <div class="col-sm-9">
                                    <date-picker
                                  v-model="selectedDateTime.time"
                                  value-type="format"
                                  type="time"
                                  :open.sync="openTime"
                                  placeholder="Select time"
                                  @change="handleChange"
                                ></date-picker>

                            </div>
                          </div>
                                
                                 

                                </b-form-group>
                               
                              </div>
                            <!-- </div> -->
                          </div>

                          <div class="form-group row mb-2" v-if="listProduct.length > 0">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Prioritas</label>
                            <div class="col-sm-9">
                              <v-select label="label" v-model="choice.priority" :options="priorityList">
                              </v-select>
                            </div>
                          </div>

                          <div class="mt-2 mb-2 text-center">

                            <b-button size="md" variant="info" style="margin-right: 0.313rem;" v-if="troubleShoot && choice.category !== 'installation'"
                              @click="runTroubleShootMethod()"> Cek Kendala
                            </b-button>
                          </div>



                          <div class="mt-2 mb-2 text-center" v-if="cardTroubleShooting">
                            <b-card>
                              <!-- <b-spinner label="Loading..." v-if="troubleShootLoading"></b-spinner> -->
                              <div class="text-start ">
                                <h5 class="mb-3">Hasil Pengecekan</h5>
                                <h6>Optical Network Unit</h6>
                                <ul>

                                  <li>
                                    <div>
                                      <b-input-group class="mb-2">
                                        <b-input-group-prepend style="margin-right: 1.25rem;">
                                          <b-button variant="primary" size="sm" class="mr-4" disabled
                                            pill>&nbsp;&nbsp;Signal&nbsp;</b-button>
                                        </b-input-group-prepend>

                                        <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.signal"
                                          :value="StateTroubleShootValue.onuSignal"></b-form-input>
                                        <b-input-group-append>
                                          <div class="loading-overlay" style="margin-top: 6px;"
                                            v-if="loadingStateTroubleShoot.signal"></div>

                                        </b-input-group-append>

                                      </b-input-group>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <b-input-group class="mb-2">
                                        <b-input-group-prepend style="margin-right: 1.25rem;">
                                          <b-button variant="primary" size="sm" class="mr-2" disabled
                                            pill>&nbsp;&nbsp;oltRx&nbsp;&nbsp;&nbsp;</b-button>
                                        </b-input-group-prepend>

                                        <b-form-input size="sm" style="width: 10%;" :state="stateTroubleShoot.oltRx"
                                          class="float-end" :value="StateTroubleShootValue.oltRx"></b-form-input>

                                        <b-input-group-append>
                                          <div class="loading-overlay" v-if="loadingStateTroubleShoot.oltRx"></div>

                                        </b-input-group-append>
                                      </b-input-group>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <b-input-group class="mb-2">
                                        <b-input-group-prepend style="margin-right: 1.25rem;">
                                          <b-button variant="primary" size="sm" class="mr-4" disabled
                                            pill>&nbsp;&nbsp;onuRx&nbsp;</b-button>
                                        </b-input-group-prepend>

                                        <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.onuRx"
                                          :value="StateTroubleShootValue.onuRx"></b-form-input>
                                        <b-input-group-append>
                                          <div class="loading-overlay" style="margin-top: 6px;"
                                            v-if="loadingStateTroubleShoot.onuRx"></div>

                                        </b-input-group-append>

                                      </b-input-group>
                                    </div>
                                  </li>

                                  <li>
                                    <div>
                                      <b-input-group class="mb-2">
                                        <b-input-group-prepend style="margin-right: 1.25rem;">
                                          <b-button variant="warning" size="sm" class="mr-4" disabled
                                            pill>&nbsp;&nbsp;Status&nbsp; </b-button>
                                        </b-input-group-prepend>

                                        <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.status"
                                          :value="StateTroubleShootValue.onuState"></b-form-input>
                                        <b-input-group-append>
                                          <div class="loading-overlay" style="margin-top: 6px;"
                                            v-if="loadingStateTroubleShoot.status"></div>

                                        </b-input-group-append>

                                      </b-input-group>
                                    </div>
                                  </li>

                                  <li>
                                    <div>
                                      <b-input-group class="mb-2">
                                        <b-input-group-prepend style="margin-right: 1.125rem;" :state="true">
                                          <b-button variant="warning" size="sm" class="mr-4" disabled pill>Up Time
                                          </b-button>
                                        </b-input-group-prepend>

                                        <b-form-input size="sm" style="width: 52%;" :state="stateTroubleShoot.upTime"
                                          :value="StateTroubleShootValue.onuUptime"></b-form-input>
                                        <b-input-group-append>
                                          <div class="loading-overlay" style="margin-top: 6px;"
                                            v-if="loadingStateTroubleShoot.upTime"></div>

                                        </b-input-group-append>

                                      </b-input-group>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <b-input-group class="mb-2">
                                        <b-input-group-prepend style="margin-right: 1.2rem;">
                                          <b-button variant="warning" size="sm" class="mr-4" disabled pill>&nbsp;Onu
                                            IP&nbsp;</b-button>
                                        </b-input-group-prepend>

                                        <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.onuIP"
                                          :value="StateTroubleShootValue.onuIP"></b-form-input>
                                        <b-input-group-append>
                                          <div class="loading-overlay" style="margin-top: 6px;"
                                            v-if="loadingStateTroubleShoot.onuIP"></div>

                                        </b-input-group-append>

                                      </b-input-group>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="accordion text-center" role="tablist">
                                      <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                          <b-button block v-b-toggle.accordion-1 variant="info" disabled>Hasil
                                            TroubleShooting</b-button>

                                        </b-card-header>
                                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                          <b-card-body style="padding-left:0px;">
                                            <ul>
                                              <li class="mb-3" v-for="items in StateTroubleShootValue.result"
                                                :key="items">
                                                <b-card-text class="text-left" align="left">{{ items }}</b-card-text>
                                              </li>

                                            </ul>
                                          </b-card-body>

                                        </b-collapse>
                                      </b-card>
                                    </div>
                                  </li>


                                </ul>
                              </div>
                            </b-card>
                          </div>

                          <div class="form-group row mb-4" v-if="listProduct.length > 0">
                            <label for="horizontal-firstname-input" class="col-md-3 col-form-label">Detail</label>
                            <div class="col-sm-9">
                              <textarea class="form-control" id="productdesc" rows="3" v-model="data.problems"></textarea>
                            </div>
                          </div>

                          <div class="mt-4">
                            <b-button type="submit" variant="primary" v-if="runTroubleShoot">Simpan</b-button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <!-- end row -->
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <a href="javascript: void(0);" class="text-dark" data-toggle="collapse"
              v-b-toggle.checkout-billinginfo-collapse1>
              <h2 class="card-title"> </h2>
              <div class="p-3">
                <div class="media align-items-center">

                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-14 mb-1">Ticket Di Selesaikan Oleh Petugas </h5>
                    <i class="text-muted text-truncate mb-0 mt-0 pb-0 font-size-10">
                      30 Hari Terakhir
                    </i>
                  </div>
                  <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-billinginfo-collapse1">
              <div class="p-2 pt-0 border-top">
                <div id="chart">
                  <apexchart type="bar" height="250" :options="chartOptions" :series="series"></apexchart>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a href="javascript: void(0);" class="text-dark" data-toggle="collapse"
              v-b-toggle.checkout-billinginfo-collapse2>
              <h2 class="card-title"> </h2>
              <div class="p-4">
                <div class="media align-items-center">

                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-14 mb-1">Kategori Ticket </h5>
                    <i class="text-muted text-truncate mb-0 mt-0 pb-0 font-size-10">
                      30 Hari Terakhir
                    </i>
                  </div>
                  <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-billinginfo-collapse2">
              <div class="p-1 border-top pt-0">
                <div id="chart">
                  <apexchart type="bar" height="250" :options="chartOptionsKategoriStacked"
                    :series="seriesKategoriStacked" @click="kategoriBar" ref="seriesKategoriStacked"></apexchart>
                </div>
              </div>
            </b-collapse>
          </div>



        </div>
        <!-- end card -->
      </div>
      <div class="col-9">
        <div class="row">
          <!-- waktu Penangganan -->
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div id="chart">
                  <apexchart type="area" height="350" :options="chartOptionsMonthFirst" :series="seriesMonthFirst"
                    ref="chartOptionsMonthFirst"></apexchart>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>

          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div id="chart">
                  <apexchart type="area" height="350" :options="chartOptionsMonthRekap" :series="seriesMonthRekap">
                  </apexchart>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div id="chart">
                  <apexchart type="area" height="350" :options="chartOptionsMonthFullResolution"
                    :series="seriesMonthFullResolutiuon" ref="chartOptionsMonthFullResolution"></apexchart>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div id="chart">
                  <apexchart type="bar" height="350" :options="chartOptionsBarDaily" :series="seriesBarDaily"
                    ref="chartOptionsBarDaily"></apexchart>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <b-modal id="modal-center" ref="modal-Admin-high" centered title="Kategori Admin High Priority " title-class="font-18"
      size="xl">

      <div>
        <b-table head-variant="light" :items="kategoriData.kategoriAdminDataHigh" :fields="fieldsDatakategori" show-empty
          striped>

          <template v-slot:cell(status)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-success': data.item.status === 'closed',
              'bg-soft-danger': data.item.status === 'open'
            }">
              {{ data.item.status }}
            </div>
          </template>
          <template #empty>
            <h5>Tidak ada data</h5>
          </template>
          <template #table-busy>
            <div class="text-center  my-2">
              <b-spinner class="xs-1" variant="primary"></b-spinner>
              <strong>Memuat data...</strong>
            </div>
          </template>

        </b-table>
      </div>


      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="hideModalAdminHigh">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>
    <b-modal id="modal-center" ref="modal-Admin-low" centered title="Kategori Admin Low Priority "
      title-class="font-18 center" size="xl">

      <div>
        <b-table head-variant="light" :items="kategoriData.kategoriAdminDataLow" :fields="fieldsDatakategori" show-empty
          striped>

          <template v-slot:cell(status)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-success': data.item.status === 'closed',
              'bg-soft-danger': data.item.status === 'open'
            }">
              {{ data.item.status }}
            </div>
          </template>
          <template #empty>
            <h5>Tidak ada data</h5>
          </template>
          <template #table-busy>
            <div class="text-center  my-2">
              <b-spinner class="xs-1" variant="primary"></b-spinner>
              <strong>Memuat data...</strong>
            </div>
          </template>

        </b-table>
      </div>


      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="hideModalAdminLow">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>
    <b-modal id="modal-center" ref="modal-Instalation-high" centered title="Kategori Instalation High Priority "
      title-class="font-18" size="xl">

      <div>
        <b-table head-variant="light" :items="kategoriData.kategoriInstalationDataHigh" :fields="fieldsDatakategori"
          show-empty striped>

          <template v-slot:cell(status)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-success': data.item.status === 'closed',
              'bg-soft-danger': data.item.status === 'open'
            }">
              {{ data.item.status }}
            </div>
          </template>
          <template #empty>
            <h5>Tidak ada data</h5>
          </template>
          <template #table-busy>
            <div class="text-center  my-2">
              <b-spinner class="xs-1" variant="primary"></b-spinner>
              <strong>Memuat data...</strong>
            </div>
          </template>

        </b-table>
      </div>


      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="hideModalInstalationHigh">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>
    <b-modal id="modal-center" ref="modal-Instalation-low" centered title="Kategori Instalation Low Priority "
      title-class="font-18" size="xl">

      <div>
        <b-table head-variant="light" :items="kategoriData.kategoriAdminDataLow" :fields="fieldsDatakategori" show-empty
          striped>

          <template v-slot:cell(status)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-success': data.item.status === 'closed',
              'bg-soft-danger': data.item.status === 'open'
            }">
              {{ data.item.status }}
            </div>
          </template>
          <template #empty>
            <h5>Tidak ada data</h5>
          </template>
          <template #table-busy>
            <div class="text-center  my-2">
              <b-spinner class="xs-1" variant="primary"></b-spinner>
              <strong>Memuat data...</strong>
            </div>
          </template>

        </b-table>
      </div>


      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="hideModalInstalationLow">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>

    <b-modal id="modal-center" ref="modal-lnternet-high" centered title="Kategori Instalation High Priority "
      title-class="font-18" size="xl">

      <div>
        <b-table head-variant="light" :items="kategoriData.kategoriInternetDataHigh" :fields="fieldsDatakategori"
          show-empty striped>

          <template v-slot:cell(status)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-success': data.item.status === 'closed',
              'bg-soft-danger': data.item.status === 'open'
            }">
              {{ data.item.status }}
            </div>
          </template>

          <template v-slot:cell(stage)="data">
            {{ data.item.stage.code }}
            <!-- <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
                      'bg-soft-success': data.item.status === 'closed',
                      'bg-soft-danger': data.item.status === 'open'
                    }">
                    
                    </div> -->
          </template>


          <template #empty>
            <h5>Tidak ada data</h5>
          </template>
          <template #table-busy>
            <div class="text-center  my-2">
              <b-spinner class="xs-1" variant="primary"></b-spinner>
              <strong>Memuat data...</strong>
            </div>
          </template>





        </b-table>
      </div>


      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="hideModallnternetHigh">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>
    <b-modal id="modal-center" ref="modal-lnternet-low" centered title="Kategori Instalation Low Priority "
      title-class="font-18" size="xl">

      <div>
        <b-table head-variant="light" :items="kategoriData.kategoriInternetDataLow" :fields="fieldsDatakategori"
          show-empty striped outlined :tbody-tr-class="rowClass" class="mb-1">

          <template v-slot:cell(status)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-success': data.item.status === 'closed',
              'bg-soft-danger': data.item.status === 'open'
            }">
              {{ data.item.status }}
            </div>
          </template>

          <template v-slot:cell(priority)="data">
            <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
              'bg-soft-danger': data.item.priority === 'high',
              'bg-soft-warning': data.item.priority === 'low'
            }">
              {{ data.item.priority }}
            </div>
          </template>




          <template #cell(stage)="row">
            <b-button size="sm" style="width: 90%; height: 20%;" @click="row.toggleDetails(); notificationChecker(row)"
              variant="outline-primary" class="mr-2">
              {{ row.detailsShowing ? 'Hide' : 'Detail' }} <span class="badge bg-danger rounded-pill"
                v-if="row.item.isAllStageRead == 'false'"><i class="mdi mdi-bell-alert text-end"></i></span>
            </b-button>
          </template>


          <template #cell(assignmentGroup)="row">

            <b-button size="sm" style="width: 90%; height: 20%;" class="mr-2" @click="updateWarningSetting(row)"
              v-if="row.item.assignmentGroup == null">
              Update
            </b-button>

            <div class="text-center bold" v-if="row.item.assignmentGroup != null">{{ row.item.assignmentGroup == null ? "" : row.item.assignmentGroup.join(', ')}}</div>
          
          </template>

          <template #row-details="row">

            <div class="custom-accordion" style="transition: 2s;">
              <b-card v-for="(item, index) in row.item.stage" :key="item._id"
                :class="item.stageData.isReadStage === 1 && item.stageDetail !== null ? 'boarder-notif-false' : 'boarder-notif-true'">

                <a href="javascript: void(0);" class="text-dark " data-toggle="collapse"
                  v-b-toggle="`checkout-kategori-collapse${item.stageData._id}`">

                  <b-row sm="10" class="mb-1 ">
                    <b-col sm="3"><b> Status Pengerjaan:

                      </b>
                      <p class="mt-2" v-if="item.stageData.code === 'ticketSubmitted'">
                        <b-button :class="{
                          'badge rounded-pill bg-danger font-size-14 font-weight-bold': item.stageData.code === 'ticketSubmitted'
                        }">
                          Ticket Di Buat

                        </b-button>
                      </p>
                      <p class="mt-2" v-if="item.stageData.code === 'ticketDone'">
                        <b-button :class="{
                          'badge rounded-pill bg-success font-size-14 font-weight-bold': item.stageData.code === 'ticketDone'
                        }">
                          Ticket Terselesaikan

                        </b-button>
                      </p>
                      <p class="mt-2" v-if="item.stageData.code === 'ticketOnProgress'">
                        <b-button
                          :class="{ 'badge rounded-pill bg-warning font-size-14 font-weight-bold': item.stageData.code === 'ticketOnProgress' }">
                          Ticket Dalam Pengerjaan

                        </b-button>
                      </p>
                      <p class="mt-2" v-if="item.stageData.code === 'ticketProgressDone'">
                        <b-button
                          :class="{ 'badge rounded-pill bg-info font-size-14 font-weight-bold': item.stageData.code === 'ticketProgressDone' }">
                          Pengerjaan Ticket Selesai

                        </b-button>
                      </p>

                    </b-col>

                    <b-col sm="2"><b> Di Update Oleh</b>
                      <p class="mt-2"> {{ item.stageData.createdBy }}</p>
                    </b-col>
                    <b-col sm="2"><b> Tanggal DiBuat</b>
                      <p class="mt-2"> {{ item.stageData.createdAt }}</p>
                    </b-col>
                    <b-col sm="2" class="mb-0"><b>
                        TroubleShoot

                      </b>


                      <div>
                        <div class="media align-items-center">
                          <p class="mb-0"><b-button size="sm" class="mt-1" variant="success"
                              style="margin-left:1.875rem;border-bottom-width:0.063rem;padding-bottom:0rem;"
                              v-if="item.stageDetail !== null && item.stageDetail.result == 'Tidak Ada Kendala'"> <i
                                class="dripicons-checkmark  font-size-14 text-end"> </i></b-button>



                            <b-button size="sm" class="mt-1" variant="warning"
                              style="margin-left:1.875rem;border-bottom-width:0.063rem;padding-bottom:0rem;"
                              v-if="item.stageDetail != null && item.stageDetail.result != 'Tidak Ada Kendala' && row.item.status == 'open'">
                              <i class="mdi mdi-exclamation-thick  font-size-14 text-end"> </i></b-button>

                            <b-button size="sm" class="mt-1" variant="warning"
                              style="margin-left:1.875rem;border-bottom-width:0.063rem;padding-bottom:0rem;"
                              v-if="item.stageDetail == null && row.item.status == 'closed'"> <i
                                class="dripicons-wrong  font-size-14 text-end"> </i></b-button>


                            <b-button size="sm" class="mt-1" variant="primary"
                              style="margin-left: 1.875rem;border-bottom-width: 0px;border-top-width: 0px;padding-bottom: 0rem;padding-top: 0px;padding-left: 0.375rem;padding-right: 0.375rem;"
                              v-if="item.stageData.code == 'ticketOnProgress' && item.stageDetail == null"> <i
                                class="mdi mdi-dots-horizontal-circle  font-size-18 text-end"> </i></b-button>

                            <b-button :id="`popover-${index}`" size="sm" class="mt-1" variant="info"
                              style="margin-left:1.875rem;padding-bottom:0rem;border-bottom-width: 0px;"
                              v-show="item.stageDetail == null && row.item.status != 'closed' && item.stageData.code != 'ticketOnProgress'">
                              <i class="dripicons-gear  font-size-14 text-end"
                                @click="showModalTroubleShootOnStage(row.item, item)"> </i></b-button>
                            <b-popover :target="`popover-${index}`" placement="bottom" title="Trouble Shoot"
                              triggers="hover focus" :content="`Lakukan Pengecekan Jaringan`"></b-popover>
                          </p>
                          <div class="media-body overflow-hidden">

                          </div>
                          <p></p>

                        </div>
                      </div>
                    </b-col>

                    <b-col sm="3"><b> Update Ticket</b>


                      <div class="media align-items-center">
                        <b-link disabled>
                          <b-form-select v-model="selectedStageDetail" :options="optionsStage" lazy
                            @change="showModalTroubleShootOnStage(row.item, item)"
                            v-if="row.item.status != 'closed' && index == row.item.stage.length - 1 && item.stageDetail != null"></b-form-select>
                        </b-link>

                        <b-link disabled>
                          <b-button size="sm" class="mt-1" variant="danger"
                            style="margin-left:1.875rem;border-bottom-width:0.063rem;padding-bottom:0rem;"
                            v-if="item.stageDetail == null && row.item.status != 'closed'"> <i
                              class="dripicons-wrong  font-size-14 text-end"> </i></b-button>
                          <b-button size="sm" class="mt-1" variant="success"
                            style="margin-left:1.875rem;border-bottom-width:0.063rem;padding-bottom:0rem;"
                            v-if="item.stageDetail !== null && index != row.item.stage.length - 1"> <i
                              class="dripicons-checkmark  font-size-14 text-end"> </i></b-button>
                        </b-link>
                        <div class="media-body overflow-hidden">
                        </div>

                        <i v-if="item.stageDetail != null" class="mdi mdi-chevron-up accor-down-icon font-size-24"> </i>
                      </div>



                    </b-col>

                  </b-row>


                </a>

                <!-- <b-button size="sm" @click="row.toggleDetails">Close</b-button> -->
                <b-collapse :id="`checkout-kategori-collapse${item.stageData._id}`" v-if="item.stageDetail !== null">
                  <div class="p-1 border-top pt-1 ">
                    <div id="chart" class="mt-3">

                      <h5><b> Hasil Trouble Shooting</b></h5>
                      <p></p>
                      <p></p>
                      <div class="text-start ">

                        <h6>Optical Network Unit</h6>

                        <ul>
                          <div class="row">
                            <div class="col-6">
                              <li>
                                <div>
                                  <b-input-group class="mb-2">
                                    <b-input-group-prepend style="margin-right: 1.25rem;">
                                      <b-button variant="primary" size="sm" class="mr-4" disabled
                                        pill>&nbsp;&nbsp;oltRx&nbsp;&nbsp;&nbsp;</b-button>
                                    </b-input-group-prepend>

                                    <b-form-input size="sm" style="width: 50%;" :state="true"
                                      :value="item.stageDetail.oltRx" disabled></b-form-input>


                                  </b-input-group>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <b-input-group class="mb-2">
                                    <b-input-group-prepend style="margin-right: 1.25rem;">
                                      <b-button variant="primary" size="sm" class="mr-4" disabled
                                        pill>&nbsp;&nbsp;onuRx&nbsp;</b-button>
                                    </b-input-group-prepend>

                                    <b-form-input size="sm" style="width: 50%;" :state="false" disabled
                                      :value="item.stageDetail.onuRx"></b-form-input>


                                  </b-input-group>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <b-input-group class="mb-2">
                                    <b-input-group-prepend style="margin-right: 1.25rem;">
                                      <b-button variant="primary" size="sm" class="mr-4" disabled
                                        pill>&nbsp;&nbsp;Signal&nbsp;</b-button>
                                    </b-input-group-prepend>

                                    <b-form-input size="sm" style="width: 50%;" :state="true"
                                      :value="item.stageDetail.onuSignal" disabled> </b-form-input>

                                  </b-input-group>
                                </div>
                              </li>
                            </div>
                            <div class="col-6">
                              <li>
                                <div>
                                  <b-input-group class="mb-2">
                                    <b-input-group-prepend style="margin-right: 1.2rem;">
                                      <b-button variant="warning" size="sm" class="mr-4" disabled pill>&nbsp;Onu
                                        IP&nbsp;</b-button>
                                    </b-input-group-prepend>

                                    <b-form-input size="sm" style="width: 50%;" :state="true"
                                      :value="item.stageDetail.onuIP" disabled></b-form-input>


                                  </b-input-group>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <b-input-group class="mb-2">
                                    <b-input-group-prepend style="margin-right: 1.125rem;" :state="true">
                                      <b-button variant="warning" size="sm" class="mr-4" disabled pill>Up Time </b-button>
                                    </b-input-group-prepend>

                                    <b-form-input size="sm" style="width: 52%;" :state="true"
                                      :value="item.stageDetail.onuUptime" disabled></b-form-input>


                                  </b-input-group>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <b-input-group class="mb-2">
                                    <b-input-group-prepend style="margin-right: 1.25rem;">
                                      <b-button variant="warning" size="sm" class="mr-4" disabled
                                        pill>&nbsp;&nbsp;Status&nbsp; </b-button>
                                    </b-input-group-prepend>

                                    <b-form-input size="sm" style="width: 50%;" :state="true"
                                      :value="item.stageDetail.onuState" disabled></b-form-input>


                                  </b-input-group>
                                </div>
                              </li>
                            </div>
                          </div>
                          <li>
                            <b-card no-body class="mb-1">
                              <b-card-header header-tag="header" class="p-1" role="tab" title-text="Hasil">

                              </b-card-header>

                              <b-card-body style="padding-left:0px;">
                                <ul>
                                  <li class="mb-3" v-for="items in item.stageDetail.result" :key="items">
                                    <b-card-text class="text-left" align="left">{{ items }}</b-card-text>
                                  </li>

                                </ul>
                              </b-card-body>


                            </b-card>
                          </li>
                        </ul>

                      </div>
                      <!-- <apexchart type="bar" height="250" :options="chartOptionsKategoriStacked" :series="seriesKategoriStacked" @click="kategoriBar" ref="seriesKategoriStacked"></apexchart> -->
                    </div>
                  </div>
                </b-collapse>
              </b-card>



            </div>
          </template>

          <!-- <template v-slot:cell(stage)="data">
                      <b-button :class="{
                        'badge rounded-pill bg-primary font-size-14 font-weight-bold': data.value === 'ticketDone',
                        'badge rounded-pill bg-danger font-size-14 font-weight-bold': data.value === 'ticketSubmitted',
                        'badge rounded-pill bg-warning font-size-14 font-weight-bold': data.value === 'ticketProgressDone',
                      }">
                         {{ data.value}}  
                         
                      </b-button>
                      <p>Show Detail</p>    
                      </template> -->


          <template #empty>
            <h5>Tidak ada data</h5>
          </template>
          <template #table-busy>
            <div class="text-center  my-2">
              <b-spinner class="xs-1" variant="primary"></b-spinner>
              <strong>Memuat data...</strong>
            </div>
          </template>

          <template>

          </template>

        </b-table>
      </div>



      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="hideModallnternetLow">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>

    <b-modal id="modal-center2" ref="modal-notif" :title-html="modalTitle" hide-footer @hide="showModalAlertData">
      <b-alert variant="danger" class="font-size-14" show @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"> Terdapat Tiket Yang Melebihi 1x24 Jam Yang Belum TerSelesaikan
        <p></p>

        <b-progress variant="warning" :max="dismissSecs" :value="currentTime" height="4px"></b-progress>
      </b-alert>
      <b-button class="float-end" variant="info" @click="hideModalNotif" justify="center">Lihat Ticket</b-button>

    </b-modal>

    <b-modal id="modal-center" ref="modal-update-petugas" centered title="Update Petugas" title-class="font-18" size="sm">
    
        <div v-for="(item, index) in selectedPetugasCount" :key="selectedPetugasCount[index]" class="mt-2">
          <transition name="fade-in">
          <div>
            <b-form-select v-model="selectedPetugas[index]" :options="optionsPetugas" lazy
              style="width:85%;height:70%;"></b-form-select>
            <b-button size="sm" variant="danger"
              style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
              class="ml-1" @click="updateAssigementSeleceted(index)" v-if ="index != selectedPetugasCount-1" ><i class="dripicons-minus" > </i>
            </b-button>
            
            <b-button size="sm" variant="success"
              style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
              class="ml-1" @click="selectedPetugasCount++" v-if ="index == selectedPetugasCount-1"><i class="dripicons-plus"> </i></b-button>
          </div>
      </transition>

      </div>


      <template #modal-footer="{ ok, cancel }">

        <!-- Emulate built in modal footer ok and cancel button actions -->

        <b-button size="md" variant="danger" class="float-start" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="success" @click="updatePatchAssigemnt()">
          Update
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-center" ref="modal-update-status" centered title="Update Status" title-class="font-18" size="sm">
      <b-form-select v-model="selectedStatus" :options="optionsStatus" lazy></b-form-select>
    </b-modal>

    <b-modal id="modal-center" ref="modal-update-stage" centered title="Update Stage" title-class="font-18" size="sm">
      <b-form-select v-model="selectedStage" :options="optionsStage" lazy></b-form-select>
    </b-modal>






    <b-modal id="modal" ref="modal-alret-Data" centered title="Ticket Proses Melebihi 1x24 " title-class="font-18"
      size="xl">
      <b-tabs content-class="mt-3" align="center">
        <b-tab :title="ticketHighModalCountHtml" active>
          <b-table head-variant="light" :items="warningData.ticketHighPriority" :fields="fieldsModalWarning" show-empty
            striped>

            <template #cell(actions)="row">
              <b-dropdown variant="link" size="lg" dropup v-model="updateWarningSelected">
                <template #button-content>
                  <span class="bx bx-dots-vertical-rounded"></span>
                </template>
                <b-dropdown-item @click="updateWarningSetting(row)">Setting Petugas</b-dropdown-item>
                <b-dropdown-item @click="updateWarningStatus(row)">Update Status</b-dropdown-item>
                <b-dropdown-item @click="updateWarningStage(row)">Update Stage</b-dropdown-item>
                <b-dropdown-item @click="updateWarningDelete(row)">Delete</b-dropdown-item>

              </b-dropdown>

            </template>
            <template #empty>
              <h5>Tidak ada data</h5>
            </template>
            <template #table-busy>
              <div class="text-center  my-2">
                <b-spinner class="xs-1" variant="primary"></b-spinner>
                <strong>Memuat data...</strong>
              </div>
            </template>

          </b-table>
        </b-tab>
        <b-tab :title="ticketLowModalCountHtml">
          <p>Tak ada Data</p>
        </b-tab>

      </b-tabs>
    </b-modal>


    <b-modal id="modal-center" ref="modal-test-card" centered title="Test view using Card Accordion "
      title-class="font-18" size="xl">

      <div class="row">
        <div class="col-3">


          <div class="custom-accordion">
            <div class="card">
              <a href="javascript: void(0);" class="text-dark" data-toggle="collapse"
                v-b-toggle.checkout-billinginfo-collapse30>
                <h2 class="card-title"> </h2>
                <div class="p-4">
                  <div class="media align-items-center">

                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-14 mb-1">Nama Pelanggan </h5>
                      <i class="text-muted text-truncate mb-0 mt-0 pb-0 font-size-10">
                        No Ticket
                      </i>
                    </div>
                    <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </a>

              <b-collapse id="checkout-billinginfo-collapse30">
                <div class="p-1 border-top pt-0">
                  <div id="chart">
                    asdasd
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <b-card>
            asd asd
          </b-card>
        </div>
        <div class="col-3">
          <b-card>
            asd asd
          </b-card>
        </div>
        <div class="col-3">
          <b-card>
            asd asd
          </b-card>
        </div>
        <div class="col-3">
          <b-card>
            asd asd
          </b-card>
        </div>
        <div class="col-3">
          <b-card>
            asd asd
          </b-card>
        </div>
        <div class="col-3">
          <b-card>
            asd asd
          </b-card>
        </div>
      </div>


      <template #modal-footer>
        <div class="w-100">

          <b-button variant="primary" size="sm" class="float-end" @click="testNewModalClose">
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header>
        <h4>Ticket Terbaru</h4>
      </template>


    </b-modal>

    <b-modal id="modal-center" ref="modal-onStage-troubleShoot" centered title="Sedang Melakukan Pengecekan "
      title-class="font-18" size="md" @hide="resetLoadingState">
      <div class="mt-2 mb-2 text-center">
        <b-card>
          <!-- <b-spinner label="Loading..." v-if="troubleShootLoading"></b-spinner> -->
          <div class="text-start">
            <h5 class="mb-3">Hasil Pengecekan</h5>
            <h6>Optical Network Unit</h6>
            <ul>

              <li>
                <div>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="margin-right: 1.25rem;">
                      <b-button variant="primary" size="sm" class="mr-4" disabled pill>&nbsp;&nbsp;Signal&nbsp;</b-button>
                    </b-input-group-prepend>
                    <div class="input-container">
                      <b-form-input size="sm" :style="stateTroubleShoot.signal !== null ? 'width: 170%;' : 'width: 198%'"
                        :state="stateTroubleShoot.signal" :value="StateTroubleShootValue.onuSignal"
                        disabled></b-form-input>

                    </div>

                    <b-input-group-append>
                      <div class="loading-overlay " v-if="loadingStateTroubleShoot.signal" style="margin-left: 8rem;
                                     margin-top: 0.375rem;"></div>

                    </b-input-group-append>

                  </b-input-group>
                </div>
              </li>
              <li>
                <div>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="margin-right: 1.25rem;" class="input-container">

                      <b-button variant="primary" size="sm" class="mr-4" disabled
                        pill>&nbsp;&nbsp;oltRx&nbsp;&nbsp;&nbsp;</b-button>
                    </b-input-group-prepend>
                    <div class="input-container">
                      <b-form-input size="sm" :style="stateTroubleShoot.oltRx !== null ? 'width: 170%;' : 'width: 198%'"
                        :state="stateTroubleShoot.oltRx" :value="StateTroubleShootValue.oltRx" disabled></b-form-input>
                    </div>

                    <b-input-group-append>
                      <div class="loading-overlay " v-if="loadingStateTroubleShoot.oltRx" style="margin-left: 8rem;
                                     margin-top: 0.375rem;"></div>

                    </b-input-group-append>
                  </b-input-group>
                </div>
              </li>
              <li>
                <div>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="margin-right: 1.25rem;">
                      <b-button variant="primary" size="sm" class="mr-4" disabled pill>&nbsp;&nbsp;onuRx&nbsp;</b-button>
                    </b-input-group-prepend>

                    <div class="input-container">
                      <b-form-input size="sm" :style="stateTroubleShoot.onuRx !== null ? 'width: 170%;' : 'width: 198%'"
                        :state="stateTroubleShoot.onuRx" :value="StateTroubleShootValue.onuRx" disabled></b-form-input>

                    </div>

                    <b-input-group-append>
                      <div class="loading-overlay " v-if="loadingStateTroubleShoot.onuRx" style="margin-left: 8rem;
                                     margin-top: 0.375rem;"></div>

                    </b-input-group-append>

                  </b-input-group>
                </div>
              </li>
              <li>
                <div>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="margin-right: 1.25rem;">
                      <b-button variant="warning" size="sm" class="mr-4" disabled pill>&nbsp;&nbsp;Status&nbsp;
                      </b-button>
                    </b-input-group-prepend>
                    <div class="input-container">
                      <b-form-input size="sm" :style="stateTroubleShoot.status !== null ? 'width: 170%;' : 'width: 198%'"
                        :state="stateTroubleShoot.status" :value="StateTroubleShootValue.onuState"
                        disabled></b-form-input>

                    </div>

                    <b-input-group-append>
                      <div class="loading-overlay " v-if="loadingStateTroubleShoot.status" style="margin-left: 8rem;
                                     margin-top: 0.375rem;"></div>

                    </b-input-group-append>

                  </b-input-group>
                </div>
              </li>

              <li>
                <div>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="margin-right: 1.125rem;" :state="true">
                      <b-button variant="warning" size="sm" class="mr-4" disabled pill>Up Time </b-button>
                    </b-input-group-prepend>
                    <div class="input-container">
                      <b-form-input size="sm" :style="stateTroubleShoot.upTime !== null ? 'width: 170%;' : 'width: 198%'"
                        :state="stateTroubleShoot.upTime" :value="StateTroubleShootValue.onuUptime"
                        disabled></b-form-input>

                    </div>
                    <b-input-group-append>
                      <div class="loading-overlay " v-if="loadingStateTroubleShoot.upTime" style="margin-left: 8rem;
                                     margin-top: 0.375rem;"></div>

                    </b-input-group-append>


                  </b-input-group>
                </div>
              </li>
              <li>
                <div>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="margin-right: 1.2rem;">
                      <b-button variant="warning" size="sm" class="mr-4" disabled pill>&nbsp;Onu IP&nbsp;</b-button>
                    </b-input-group-prepend>

                    <div class="input-container">
                      <b-form-input size="sm"
                        :style="stateTroubleShoot.onuIP !== null ? 'width: 170%;' : 'width: 198%'"
                        :state=stateTroubleShoot.onuIP :value="StateTroubleShootValue.onuIP" disabled>


                      </b-form-input>


                    </div>

                    <b-input-group-append>
                      <div class="loading-overlay " v-if="loadingStateTroubleShoot.onuIP" style="margin-left: 8rem;
                                     margin-top: 0.375rem;"></div>

                    </b-input-group-append>



                  </b-input-group>

                </div>
              </li>

              <li>
                <div class="accordion text-center" role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-a variant="info">Hasil TroubleShooting</b-button>

                    </b-card-header>
                    <b-collapse id="accordion-a" visible accordion="my-accordion" role="tabpanel">
                      <b-card-body style="padding-left:0px;">
                        <ul>
                          <li class="mb-3" v-for="items in StateTroubleShootValue.result" :key="items">
                            <b-card-text class="text-left" align="left">{{ items }}</b-card-text>
                          </li>

                        </ul>
                      </b-card-body>

                    </b-collapse>
                  </b-card>
                </div>
              </li>


            </ul>
          </div>
        </b-card>
      </div>

      <template #modal-footer="{ ok, cancel }">

        <!-- Emulate built in modal footer ok and cancel button actions -->

        <b-button size="md" variant="danger" class="float-start" style="margin-right:23.938rem;" @click="cancel()"
          v-if="StateTroubleShootValue.result !== null">
          Close
        </b-button>
        <b-button size="md" variant="success" v-if="StateTroubleShootValue.result !== null &&
          StateTroubleShootValue.onuState !== 'working'" @click="updateStageOnDetail(row)">
          Update
        </b-button>
      </template>

      <!-- <div class="form-group row mb-4" v-if="listProduct.length > 0">
                      <label
                        for="horizontal-firstname-input"
                        class="col-md-3 col-form-label"
                        >Detail</label
                      >
                      <div class="col-sm-9">
                        <textarea
                          class="form-control"
                          id="productdesc"
                          rows="3"
                          v-model="data.problems"
                        ></textarea>
                      </div>
                  </div> -->


    </b-modal>

  </Layout>
</template>

<style>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
  color: #fff;
}

.custom-icon {
  font-size: 1.25rem;
  /* Adjust the font size as needed */
  margin-top: 0.313rem;
  padding-left: 0.313rem;

}

.input-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  width: 1.163rem;
  height: 1.163rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #333;
  animation: spin 1s infinite;
}

.row-closed {
  background-color: #d4edda;
}

.row-open {
  background-color: #f8d7da;
}

.priority-high {
  border-left: 5px solid #dc3545;
}

.priority-low {
  border-left: 5px solid #ffc107;
}


.boarder-notif-true {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(241, 79, 79);
  border-radius: 0.1rem;
  transition: border-color 3s ease;
}

.boarder-notif-false {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(79, 241, 95);
  border-radius: 0.1rem;
  transition: border-color 3s ease;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 1s ease;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}






.transition-example {
  background: transparent;
  border: 2px solid blue;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin: 10px;
  transition: border-color 3s ease;
}
</style>